function openSideMenu(menu) {
    if (!menu.open && window.innerWidth > 768) {
        menu.style.transform = 'scale(2)';
        menu.open = true;
        setTimeout(() => {
            if (menu.open) {
                menu.style.height = '' + 62.5/2 + 'vh';
            }
        }, 200);
    }
}

function closeSideMenu(menu) {
    menu.style.transform = 'scale(1)';
    menu.style.height = '' + 62.5 + 'vh';
    menu.open = false;
}

const menus = document.getElementsByClassName('side-menu');
for(let i=0; i < menus.length; i++) {
    menus[i].addEventListener('mouseenter', () => openSideMenu(menus[i]));
    menus[i].addEventListener('mouseMove', () => openSideMenu(menus[i]));
    menus[i].addEventListener('mouseleave', () => closeSideMenu(menus[i]));
};